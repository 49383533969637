import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {Currency} from '../core/_models'
import {CurrencyListLoading} from '../components/loading/CurrencyListLoading'
import {CurrencyListPagination} from '../components/pagination/CurrencyListPagination'
import {KTCardBody} from '../../../../../_metronic/helpers'

const CurrencyTable = ({currency_columns}) => {
  const currency = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => currency, [currency])
  const columns = useMemo(() => currency_columns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })


  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive mb-10'>
        <table
          id='kt_table_currency'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer table-bordered'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Currency>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          {/* <tbody className='text-gray-600' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Currency>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody> */}
        </table>
      </div>
      <CurrencyListPagination />
      {isLoading && <CurrencyListLoading />}
    </KTCardBody>
  )
}

export {CurrencyTable}
