// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const displaychannellogos = createSlice({
  name:"displaychannellogos",
  initialState: {
    isToggled: "",
  },
  reducers:{
    displaychannelLogos: (state,action) => {
        state.isToggled = action.payload;
      },
  }
}) 
export default displaychannellogos.reducer;
export const {displaychannelLogos} = displaychannellogos.actions;