/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { KTIcon, useDebounce } from '../../../../../../_metronic/helpers'
import { initialQueryState } from '../../../../../../_metronic/helpers/crud-helper/custom-models'
import { useQueryRequest } from '../../core/QueryRequestProvider'

const ProductsListSearchComponent = (props) => {
    const { updateState, state } = useQueryRequest()
    const [searchTerm, setSearchTerm] = useState<string>((state.search === undefined) ? '' : state.search)
    // Debounce search term so that it only gives us latest value ...
    // ... if searchTerm has not been updated within last 500ms.
    // The goal is to only have the API call fire when product stops typing ...
    // ... so that we aren't hitting our API rapidly.
    const debouncedSearchTerm = useDebounce(searchTerm, 150)
    // const [total_pages, setTotal_pages] = useState<any>(state.limit)
    // Effect for API call

    const HandleChange = (e) => {
        setSearchTerm(() => e.target.value)
        props?.getData && props?.getData(e.target.value)
        props?.getDataTotalProducts && props?.getDataTotalProducts(e.target.value)
        props?.getDatatotalbuyers && props?.getDatatotalbuyers(e.target.value)
        props?.getDataTotaladmins && props?.getDataTotaladmins(e.target.value)
        props?.getDatarisk_products && props?.getDatarisk_products(e.target.value)
        props?.getDatabonus_products && props?.getDatabonus_products(e.target.value)
    }
    useEffect(
        () => {
            if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
                updateState({ search: debouncedSearchTerm.trim(), ...initialQueryState })
            }
        },
        [debouncedSearchTerm] // Only call effect if debounced search term changes
        // More details about useDebounce: https://usehooks.com/useDebounce/
    )

    return (
        // <div className='card-title'>

        <div className='d-flex align-items-center position-relative my-1'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
                type='search'
                data-kt-product-table-filter='search'
                className='form-control form-control-solid w-350px ps-14'
                placeholder='Search'
                // placeholder={props?.getDataTotaladmins ? "Search Name" : "Search SKU"}
              
                value={searchTerm}
                onChange={HandleChange}
            />
        </div>

        // </div>
    )
}

export { ProductsListSearchComponent }
