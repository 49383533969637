import { Tooltip } from 'react-tooltip'
import { ProductsListSearchComponent } from '../../modules/products/products-list/components/header/ProductsListSearchComponent'
import React, { useEffect, useState } from 'react'
import { KTCard } from '../../../_metronic/helpers'
import { Button, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../../modules/auth'
import ReactPaginate from 'react-paginate'
import { useLocation, useNavigate } from 'react-router-dom'
import CommonPagination from '../../../_metronic/helpers/components/CommonPagination'

let productindexNumber = 0

export const Saleprice_productaprovel: React.FC = () => {
    const { search } = useLocation();
    let searchData: any = search
    let searchKey: any = searchData?.split("=")[0]
    let searchValue: any = searchData?.split("=")[1]

    let { currentUser } = useAuth();
    const API_URL = process.env.REACT_APP_KT_API_URL
    const PRODUCT_API_URL = `${API_URL}/product`
    const tokenString: any = localStorage.getItem('kt-auth-react-v')
    const token = JSON.parse(tokenString)
    const [tabledata, setTableData] = useState<any>(null)
    const [showModal, setShowModal] = useState(false)
    const [modal_btn_text, setModal_btn_text] = useState(true)
    const [texttrue, setTrue] = useState<any>(false)
    const [searchBar, setSearchbar] = useState({ search: '' });
    const [total_pages, setTotal_pages] = useState<any>(null)
    const [currentPage, setCurrentPage] = useState(1);
    const [total_items, setTotal_Items] = useState<any>(null)
    const [pageSize, setPageSize] = useState<any>(10)
    // const perPage = 10;
    const [data, setData] = useState<any>({
        is_approval: '',
        add_note: '',
    })

    const navigate = useNavigate();
    const urlWithoutId = new URL(`https://profitmargintool.dikonia.in/dashboard/Saleprice-productapproval`);

    // toast center align
    const toastError = (errorMessage) => {
        toast.error(errorMessage, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000
        });
    };

    const fetchDataForProductRequest = async () => {
        setTableData(null)
        try {
            // if (!searchKey || !searchValue) {
            //     throw toastError("wrong url");
            // }
            const params = new URLSearchParams();
            const userType = currentUser?.roles && currentUser?.roles[0]?.id;
            if (userType !== null && userType !== undefined) {
                params.append("user_type_id", userType.toString());
            }
            Object.keys(searchBar).forEach((key) => {
                if (searchBar.search !== "") {
                    params.append(key, searchBar[key]);
                }
            });
            const queryString = params.toString();

            let url = `${API_URL}/dashboard/ApprovalWorkflowsSaleProduct?page=${currentPage}&limit=${pageSize}&${queryString}`;
            if (searchKey === "?id") {
                url = `${API_URL}/dashboard/ApprovalWorkflowsSaleProduct?id=${searchValue}&page=${currentPage}&limit=${pageSize}&${queryString}`;
            }
            const response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${token.access_token}`,
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            if (!response.ok) {
                throw toastError(data?.message);
            }


            setTableData(data?.data?.items);
            setTotal_pages(data?.data?.meta?.totalPages);
            setTotal_Items(data?.data?.meta?.totalItems)
            productindexNumber = 0;
        } catch (error) {
            console.error(error);
            // toastError(error.message || "An error occurred");
        }
    };

    // const handlePageChange = (selectedPage) => {
    //     setCurrentPage(selectedPage.selected + 1);
    // };

  const handlePageChange = (event, selectedPage) => {
    productindexNumber = 0
    setCurrentPage(selectedPage)
};

const handlePageSizeChange = (event) => {
    setPageSize(event.target.value)
    setCurrentPage(1); 
    productindexNumber=0
  }

    const checkApproved_request = async () => {
        productindexNumber = 0
        try {
            const response = await fetch(`${PRODUCT_API_URL}/productApprovedByadmin`, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${token.access_token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            const responseData = await response.json();
            if (!response.ok) {
                throw toast.error(responseData?.message);
            }
            setData({ add_note: "", is_approval: "", });
            setTrue(false)
            toast.success(responseData?.message)
            setShowModal(false)
            navigate(urlWithoutId);       
            searchKey=""   
            fetchDataForProductRequest();
            productindexNumber = 0
        } catch (error) {
            console.log(error)
            productindexNumber = 0
            // toast.error(error?.message);
        }
    };

    const toggleModal = () => {
        setData({ add_note: "", is_approval: "", });
        setTrue(false)
        setShowModal(!showModal)
        productindexNumber = 0
    }

    const HandleModal = (id, value, check_data) => {
        setShowModal(true)
        setData((prevData) => ({ ...prevData, ['is_approval']: value }))
        if (check_data === "sale_activities_id") {
            setData((prevData) => ({ ...prevData, ['sale_activities_id']: id }))
            // setData((prevData) => ({ ...prevData, ['channel_calculations_id']: id }))
        }
        productindexNumber = 0
        if (value !== "APPROVED") {
            setModal_btn_text(false)
        } else {
            setModal_btn_text(true)
        }
    }

    const handleClose = () => {
        productindexNumber = 0
        setShowModal(false)
        productindexNumber = 0
        setData({ add_note: "", is_approval: "", });
        setTrue(false)
    }

    const handletextarea = (e) => {
        productindexNumber = 0
        setData((prevData) => ({ ...prevData, ['add_note']: e.target.value }))
        setTrue(true)
    }

    const HandleSubmitApproval = (e) => {
        productindexNumber = 0
        e.preventDefault();
        if (data.add_note !== '') {
            productindexNumber = 0
            checkApproved_request();
        }
        setTrue(true)
    }

    useEffect(() => {
        fetchDataForProductRequest();
        productindexNumber = 0
    }, [searchBar, currentPage, navigate,pageSize])

    const getData: (value: any) => void = (value) => {
        productindexNumber = 0
        setSearchbar((search) => ({ ...search, search: value }))
    };

    return (
        <React.Fragment>
            <KTCard>
                <div className='card-header border-0 pt-6 pb-6'>
                    <Tooltip id='my-tooltip' />
                    <ProductsListSearchComponent getData={getData} />
                    <div className='table-responsive pt-6 w-100'>
                        {/* <table className='approvel-table table align-middle gs-0 gy-4 product-table-wrap product-maintable-wrap table-sub-bordered setCustom_width'> */}
                        <table className='table align-middle table-bordered table-row-dashed gy-5 dataTable no-footer rounded'>
                            <thead>
                                <tr className='fw-bold text-dark text-uppercase'>
                                    <th className='ps-4 w-60px'>S.No</th>
                                    {/* <th>Product name</th> */}
                                    <th>ITEM SKU</th>
                                    <th>SKU DESCRIPTION</th>
                                    <th className='channel_name' style={{ padding: 10 }}>
                                        channel name
                                    </th>
                                    <th>BUYER CODE</th>
                                    <th className='text-center'>Sales Date</th>
                                    <th>status</th>
                                    <th>
                                        <div>
                                        Weighted Avg. Cost
                                            {/* <span
                                                className='svg-icon svg-icon-primary svg-icon-2x ms-2 cursor-pointer'
                                                data-tooltip-id='my-tooltip'
                                                data-tooltip-content='Weighted Avg. Cost Price'
                                            >
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='24px'
                                                    height='24px'
                                                    viewBox='0 0 24 24'
                                                    version='1.1'
                                                >
                                                    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                                                        {' '}
                                                        <rect x='0' y='0' width='24' height='24'></rect>
                                                        <circle fill='#000000' opacity='0.2' cx='12' cy='12' r='10'></circle>
                                                        <rect
                                                            fill='#000000'
                                                            opacity='0.5'
                                                            x='11'
                                                            y='10'
                                                            width='2'
                                                            height='7'
                                                            rx='1'
                                                        ></rect>
                                                        <rect
                                                            fill='#000000'
                                                            opacity='0.5'
                                                            x='11'
                                                            y='7'
                                                            width='2'
                                                            height='2'
                                                            rx='1'
                                                        ></rect>
                                                    </g>
                                                </svg>
                                            </span> */}
                                        </div>
                                    </th>
                                    <th>
                                        {' '}
                                        <div>
                                            List Price (USD)
                                            <span
                                                className='svg-icon svg-icon-primary svg-icon-2x ms-2 cursor-pointer'
                                                data-tooltip-id='my-tooltip'
                                                data-tooltip-content='Channel Currency'
                                            >
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='24px'
                                                    height='24px'
                                                    viewBox='0 0 24 24'
                                                    version='1.1'
                                                >
                                                    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                                                        <rect x='0' y='0' width='24' height='24'></rect>
                                                        <circle fill='#000000' opacity='0.2' cx='12' cy='12' r='10'></circle>
                                                        <rect
                                                            fill='#000000'
                                                            opacity='0.5'
                                                            x='11'
                                                            y='10'
                                                            width='2'
                                                            height='7'
                                                            rx='1'
                                                        ></rect>
                                                        <rect
                                                            fill='#000000'
                                                            opacity='0.5'
                                                            x='11'
                                                            y='7'
                                                            width='2'
                                                            height='2'
                                                            rx='1'
                                                        ></rect>
                                                    </g>
                                                </svg>
                                            </span>
                                        </div>
                                    </th>
                                    <th>
                                        <div>
                                            Channel Cost Price
                                            <span
                                                className='svg-icon svg-icon-primary svg-icon-2x ms-2 cursor-pointer'
                                                data-tooltip-id='my-tooltip'
                                                data-tooltip-content='Price Listed on Channel'
                                            >
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='24px'
                                                    height='24px'
                                                    viewBox='0 0 24 24'
                                                    version='1.1'
                                                >
                                                    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                                                        <rect x='0' y='0' width='24' height='24'></rect>
                                                        <circle fill='#000000' opacity='0.2' cx='12' cy='12' r='10'></circle>
                                                        <rect
                                                            fill='#000000'
                                                            opacity='0.5'
                                                            x='11'
                                                            y='10'
                                                            width='2'
                                                            height='7'
                                                            rx='1'
                                                        ></rect>
                                                        <rect
                                                            fill='#000000'
                                                            opacity='0.5'
                                                            x='11'
                                                            y='7'
                                                            width='2'
                                                            height='2'
                                                            rx='1'
                                                        ></rect>
                                                    </g>{' '}
                                                </svg>
                                            </span>
                                        </div>
                                    </th>
                                    <th>
                                        <span className='d-block'>
                                            Sell Price
                                            <span
                                                className='svg-icon svg-icon-primary svg-icon-2x ms-2 p-0 cursor-pointer'
                                                data-tooltip-id='my-tooltip'
                                                data-tooltip-content='Price after channel margin term &amp; return'
                                            >
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='24px'
                                                    height='24px'
                                                    viewBox='0 0 24 24'
                                                    version='1.1'
                                                >
                                                    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                                                        <rect x='0' y='0' width='24' height='24'></rect>
                                                        <circle fill='#000000' opacity='0.2' cx='12' cy='12' r='10'></circle>
                                                        <rect
                                                            fill='#000000'
                                                            opacity='0.5'
                                                            x='11'
                                                            y='10'
                                                            width='2'
                                                            height='7'
                                                            rx='1'
                                                        ></rect>
                                                        <rect
                                                            fill='#000000'
                                                            opacity='0.5'
                                                            x='11'
                                                            y='7'
                                                            width='2'
                                                            height='2'
                                                            rx='1'
                                                        ></rect>
                                                    </g>
                                                </svg>
                                            </span>
                                        </span>
                                    </th>
                                    <th>
                                        <span className='d-block'>Net Profit Amount </span>
                                    </th>
                                    <th>
                                        <span className='d-block'>Net profit percent </span>
                                    </th>
                                    {currentUser?.roles && currentUser?.roles?.length > 0 && currentUser?.roles?.[0].id === 3 ? "" :
                                        <th className='text-center rounded-end ps-4 w-150px'>Actions</th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    tabledata && tabledata.length > 0 ?
                                        tabledata?.map?.((items, index) => {
                                            return items?.productChannelCalculations?.product?.channels?.map((channel, channelsindex) => {
                                                var start_date = new Date(items.start_date).toISOString().split('T')[0].split('-').reverse().join('-');
                                                var end_date = new Date(items.end_date).toISOString().split('T')[0].split('-').reverse().join('-');
                                                (productindexNumber = productindexNumber + 1)

                                                return (
                                                    <React.Fragment>
                                                        {
                                                            <tr key={(pageSize * (currentPage - 1) + productindexNumber)}>
                                                                <td className='ps-4'>{(pageSize * (currentPage - 1) + productindexNumber)}</td>
                                                                {/* <td>{items.name}</td> */}
                                                                <td>{items.productChannelCalculations.product.sku}</td>
                                                                <td>{items.productChannelCalculations.product.description}</td>
                                                                <td key={index}>{channel.channel_name}</td>
                                                                <td style={{ textAlign: "center" }}>
                                                                    {
                                                                        items?.productChannelCalculations?.product?.buyer_codes && items?.productChannelCalculations?.product?.buyer_codes?.length > 0 ? items?.productChannelCalculations?.product?.buyer_codes?.map((data, index) => (
                                                                            <div key={index}>
                                                                                {data?.buyerCodes?.code}
                                                                                {index !== items?.productChannelCalculations?.product?.buyer_codes?.length - 1 && ' ,'}
                                                                            </div>
                                                                        ))
                                                                            : "N/A"
                                                                    }
                                                                </td>
                                                                <td style={{ minWidth: 200, textAlign: "center" }}>
                                                                    <span>{`${start_date} / ${end_date}`}</span>
                                                                </td>
                                                                <td>
                                                                    <span className={`badge ${items.price_status === "At Risk" ? "badge-light-danger" : "badge-light-info"}`}>
                                                                        {items.price_status}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <div className='custom-width-set'>
                                                                        <div className='input-group'>
                                                                            <div className='input-group-prepend'>
                                                                                <span className='input-group-text edit-fields' id='inputGroupPrepend'>
                                                                                    {
                                                                                        new Intl.NumberFormat('en-US', {
                                                                                            style: 'currency',
                                                                                            currency: channel.currency_code.split('-')[0],
                                                                                        }).formatToParts()[0].value
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <input
                                                                                type='number'
                                                                                name='list_price'
                                                                                value={items.productChannelCalculations.product.cost_price}
                                                                                className='form-control edit-fields w-50px'
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                {/* <td key={index}>{channel.channel_name}</td> */}
                                                                <td>
                                                                    <div className='input-group'>
                                                                        <div className='input-group-prepend'>
                                                                            <span
                                                                                className='input-group-text edit-fields'
                                                                                id='inputGroupPrepend'
                                                                            >
                                                                                $
                                                                            </span>
                                                                        </div>
                                                                        <input
                                                                            type='text'
                                                                            className='form-control edit-fields w-50px'
                                                                            value={items.list_price}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className='input-group'>
                                                                        <div className='input-group-prepend'>
                                                                            <span
                                                                                className='input-group-text edit-fields'
                                                                                id='inputGroupPrepend'
                                                                            >
                                                                                $
                                                                            </span>
                                                                        </div>
                                                                        <input
                                                                            type='text'
                                                                            className='form-control edit-fields w-50px'
                                                                            value={items.channel_cost_price}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span>$</span>
                                                                    {items.sell_price}
                                                                </td>
                                                                <td>
                                                                    <span>$</span>
                                                                    {items.net_profit_amount}
                                                                </td>
                                                                <td>
                                                                    <span>$</span>
                                                                    {items.net_profit_percent}
                                                                </td>
                                                                {currentUser?.roles && currentUser?.roles?.length > 0 && currentUser?.roles?.[0].id === 3 ? "" :
                                                                    <td className='text-end'>
                                                                        <div className='d-flex justify-content-end gap-2 p-0'>
                                                                            <button
                                                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                                                data-tooltip-id='my-tooltip'
                                                                                data-tooltip-content='Accept'
                                                                                onClick={() => HandleModal(items.id, "APPROVED", "sale_activities_id")}
                                                                            >
                                                                                {/* <Tooltip id='my-tooltip' /> */}
                                                                                <span className='svg-icon svg-icon-primary svg-icon-2x'>
                                                                                    {' '}
                                                                                    <svg
                                                                                        xmlns='http://www.w3.org/2000/svg'
                                                                                        width='24px'
                                                                                        height='24px'
                                                                                        viewBox='0 0 24 24'
                                                                                        version='1.1'
                                                                                    >
                                                                                        <g
                                                                                            stroke='none'
                                                                                            strokeWidth='1'
                                                                                            fill='none'
                                                                                            fillRule='evenodd'
                                                                                        >
                                                                                            <rect x='0' y='0' width='24' height='24' />
                                                                                            <circle
                                                                                                fill='#1BC5BD'
                                                                                                opacity='0.3'
                                                                                                cx='12'
                                                                                                cy='12'
                                                                                                r='10'
                                                                                            />
                                                                                            <path
                                                                                                d='M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z'
                                                                                                fill='#1BC5BD'
                                                                                                fillRule='nonzero'
                                                                                            />
                                                                                        </g>
                                                                                    </svg>
                                                                                </span>
                                                                            </button>
                                                                            <button
                                                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                                                data-tooltip-id='my-tooltip'
                                                                                data-tooltip-content='Reject'
                                                                                onClick={() => HandleModal(items.id, "REJECTED", "sale_activities_id")}
                                                                            >
                                                                                {/* <Tooltip id='my-tooltip' /> */}
                                                                                <span className='svg-icon svg-icon-primary svg-icon-2x'>
                                                                                    <svg
                                                                                        xmlns='http://www.w3.org/2000/svg'
                                                                                        width='24px'
                                                                                        height='24px'
                                                                                        viewBox='0 0 24 24'
                                                                                        version='1.1'
                                                                                    >
                                                                                        <g
                                                                                            stroke='none'
                                                                                            strokeWidth='1'
                                                                                            fill='none'
                                                                                            fillRule='evenodd'
                                                                                        >
                                                                                            <rect x='0' y='0' width='24' height='24' />
                                                                                            <circle
                                                                                                fill='#F64E60'
                                                                                                opacity='0.3'
                                                                                                cx='12'
                                                                                                cy='12'
                                                                                                r='10'
                                                                                            />
                                                                                            <path
                                                                                                d='M12.0355339,10.6213203 L14.863961,7.79289322 C15.2544853,7.40236893 15.8876503,7.40236893 16.2781746,7.79289322 C16.6686989,8.18341751 16.6686989,8.81658249 16.2781746,9.20710678 L13.4497475,12.0355339 L16.2781746,14.863961 C16.6686989,15.2544853 16.6686989,15.8876503 16.2781746,16.2781746 C15.8876503,16.6686989 15.2544853,16.6686989 14.863961,16.2781746 L12.0355339,13.4497475 L9.20710678,16.2781746 C8.81658249,16.6686989 8.18341751,16.6686989 7.79289322,16.2781746 C7.40236893,15.8876503 7.40236893,15.2544853 7.79289322,14.863961 L10.6213203,12.0355339 L7.79289322,9.20710678 C7.40236893,8.81658249 7.40236893,8.18341751 7.79289322,7.79289322 C8.18341751,7.40236893 8.81658249,7.40236893 9.20710678,7.79289322 L12.0355339,10.6213203 Z'
                                                                                                fill='#F64E60'
                                                                                            />
                                                                                        </g>
                                                                                    </svg>
                                                                                </span>
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                }
                                                            </tr>
                                                        }
                                                    </React.Fragment>
                                                )
                                            })
                                        })

                                        : <tr>
                                            <td colSpan={14}>
                                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                    No matching records found
                                                </div>
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    {/* {
                        tabledata && tabledata?.length > 0 &&
                        <ReactPaginate
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            pageCount={total_pages}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination justify-content-end mt-2 mb-3 ms-auto"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                        />
                    } */}
                        <div style={{width:"100%"}}>
                    {
                        tabledata && tabledata?.length > 0 &&
                        // <ReactPaginate
                        //     previousLabel={"previous"}
                        //     nextLabel={"next"}
                        //     breakLabel={"..."}
                        //     pageCount={total_pagess}
                        //     marginPagesDisplayed={2}
                        //     pageRangeDisplayed={3}
                        //     onPageChange={handlePageChange}
                        //     containerClassName={"pagination justify-content-end mt-2 mb-3 ms-auto"}
                        //     pageClassName={"page-item"}
                        //     pageLinkClassName={"page-link"}
                        //     previousClassName={"page-item"}
                        //     previousLinkClassName={"page-link"}
                        //     nextClassName={"page-item"}
                        //     nextLinkClassName={"page-link"}
                        //     breakClassName={"page-item"}
                        //     breakLinkClassName={"page-link"}
                        //     activeClassName={"active"}
                        // />

                        <CommonPagination
                        currentPage={currentPage}
                        pageSize={pageSize}
                        totalItems={total_items}
                        totalPages={total_pages}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        title={'results'}
                      />
                    }
                     </div>
                </div>
            </KTCard>

            {/* modal */}
            <Modal show={showModal} onHide={toggleModal}>
                <Modal.Body>
                    <span className='svg-icon svg-icon-primary svg-icon-5x d-block text-center mb-5'>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24px'
                            height='24px'
                            viewBox='0 0 24 24'
                            version='1.1'
                        >
                            <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                                <rect x='0' y='0' width='24' height='24' />
                                <circle fill='#000000' opacity='0.3' cx='12' cy='12' r='10' />
                                <rect fill='#000000' x='11' y='10' width='2' height='7' rx='1' />
                                <rect fill='#000000' x='11' y='7' width='2' height='2' rx='1' />
                            </g>
                        </svg>
                    </span>
                    <p className='text-center text-muted'>
                        The price change that is being actioned here is leading to the product going below the threshold profit percentage for this channel. If you would like to proceed, please add a Note below.
                    </p>
                    <textarea
                        name='add_note'
                        className='form-control'
                        // value={data.add_note}
                        onChange={handletextarea}
                    ></textarea>
                    {data.add_note === '' && texttrue ? (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>It is required</span>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}

                    {/* )} */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='primary btn-sm' onClick={HandleSubmitApproval}>
                        {' '}
                        {modal_btn_text && modal_btn_text ? "Approve" : "Reject"}
                    </Button>
                    <Button variant='secondary btn-sm' onClick={handleClose}>
                        {' '}
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment >
    )
}
