import {Dispatch, SetStateAction} from 'react'

export type ID = number |undefined | null

export type PaginationState = {
  page: number
  limit: 10 | 30 | 50 | 100
  links?: Array<{label: string; active: boolean; url: string | null; page: number | null}>
  stats?: string
}

export type SortState = {
  sort?: string
  order?: 'asc' | 'desc'
}

export type FilterState = {
  filter?: any
}

export type SearchState = {
  search?: string
}

export type MetaState = {
  totalItems: number
  itemCount: number
  itemsPerPage: 10 | 30 | 50 | 100
  totalPages: number
  currentPage: number
}

export type LinksState = {
  first: string
  previous: string
  next: string
  last: string
}

export type PaginationResponse<T> = {
  map(arg0: (row: import("react-table").Row<import("../../../app/modules/ibs-products-sync/products-list/core/_models").Product>, i: any) => JSX.Element): import("react").ReactNode
  length: number
  items: Array<T>
  meta: MetaState
  links: LinksState
}

export type Response<T> = {
  total: number
  forEach(arg0: (currency: { currency_code: any; currency_description: any }) => void): unknown
  statusCode?: number
  message?: string
  error?: string
  errors?: {
    [key: string]: Array<string>
  }
  data?: T
}

export type QueryState = PaginationState & SortState & FilterState & SearchState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
  page: 1,
  limit: 10,
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
}

export type QueryResponseContextProps<T> = {
  response?: Response<PaginationResponse<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export type QuerySingleResponseContextProps<T> = {
  response?: T | undefined
  refetch: () => void
  isLoading: boolean
  query: string
  error?: any
  isError?: boolean
}

export const initialQueryResponse = {refetch: () => {}, isLoading: false, query: ''}

export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  isAllSelected: boolean
  disabled: boolean
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  setItemIdForUpdate: () => {},
  isAllSelected: false,
  disabled: false,
}


export type ProductsListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  itemIdForReassign?: ID
  setItemIdForReassign: Dispatch<SetStateAction<ID>>
  isAllSelected: boolean
  disabled: boolean
  editingRow?: any
  setEditingRow: Dispatch<SetStateAction<ID>>
  inlineEditPrice: number,
  setInlineEditPrice: Dispatch<SetStateAction<number>>
  ItemIdForViewSales?: any
  setItemIdForViewSales : Dispatch<SetStateAction<ID>>
  ItemIdForViewSalesEdit?: any
  setItemIdForViewSalesEdit : Dispatch<SetStateAction<any>>

}

export const initialProductListView: ProductsListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  setItemIdForUpdate: () => {},
  setItemIdForReassign: () => {},
  isAllSelected: false,
  disabled: false,
  setEditingRow: () => {},
  inlineEditPrice: 0,
  setInlineEditPrice: () => {},
  setItemIdForViewSales: () => {},
  setItemIdForViewSalesEdit: () => {}
}