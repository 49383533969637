import { createSlice } from '@reduxjs/toolkit'
const ExcludeProductswithzero = createSlice({
  name:"ExcludeProductswithzero",
  initialState: {
    isToggled: false,
  },
  reducers:{
    excludeProductswithzeroprice: state => {
        state.isToggled = !state.isToggled;
      },
  }
}) 
export default ExcludeProductswithzero.reducer;
export const {excludeProductswithzeroprice} = ExcludeProductswithzero.actions;