
const QUERIES = {
  USERS_LIST: 'users-list',
  ROLES_LIST: 'roles-list',
  ALL_ROLES_LIST: 'all-role-list',
  BUYERS_LIST: 'users-list',
  PRODUCTS_LISTS: 'products-list',
  SELLER_PRODUCTS_LISTS: 'seller-product-list',
  AMAZON_ACCOUNTS: 'amazon-accounts',
  SELLER_ACCOUNTS:'seller-accounts',
  PERMISSIONS_LIST: 'permissions-list',
  CHANNELS_LIST: 'channels-list',
  CURRENCY_LIST: 'currency-list',
  PRODUCT_FEEDS_LIST: 'product_feed_list',
  PRODUCT_REASSIGN_USERS_LIST: 'product-reassign-users-lists',
  PRODUCT_SALES_LIST: 'product-sales-list',
  AMAZON_ACCOUNT_LIST: 'amazon-account-dropdown-list',
  ASSIGN_PRODUCT_LIST: 'assign_product_list',
  AUTH_USER: 'auth_user',
  ALL_PRODUCTS:'all-products'
  // BUYER_CODES_LIST: 'buyer-codes-list',
}

export {QUERIES}
