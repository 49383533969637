// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit';

const ibsSelectedActionSlice = createSlice({
  name:"productAction",
  initialState: [],
  reducers:{
    UpdateSelected(state,action){
        const {userId, isChecked} = action.payload;
        const allSelected = state;
        const newSetCre = new Set(allSelected);
        if(isChecked)
            newSetCre.add(userId)
        else newSetCre.delete(userId);
        const finalProducts = Array.from(newSetCre);
        return finalProducts;
    },
    SelectAllProductsCheck(state, action){
        const {allProducts, isChecked} = action.payload;
        if(isChecked) return allProducts;
        else return [];
    }
  }
}) 

export default ibsSelectedActionSlice.reducer;
export const {UpdateSelected, SelectAllProductsCheck}= ibsSelectedActionSlice.actions;