import { createSlice } from '@reduxjs/toolkit';

// Step 1: Define the slice
const OldPriceSlice = createSlice({
  name: 'OldPrice',
  initialState: {
    value: 0,
  },
  reducers: {
    saveOldListPrice: (state,action) => {
      state.value = action?.payload // Mutates the state directly (thanks to Immer under the hood)
    },
  },
});

// Step 2: Export actions
export const { saveOldListPrice } = OldPriceSlice.actions;

// Step 3: Export reducer
export default OldPriceSlice.reducer;
