import { createSlice } from '@reduxjs/toolkit'
const ExcludeProductswithzero_profit_margin = createSlice({
  name:"ExcludeProductswithzero",
  initialState: {
    isToggled: false,
  },
  reducers:{
    excludeProductswithzeroprice_profitMargin: state => {
        state.isToggled = !state.isToggled;
      },
  }
}) 
export default ExcludeProductswithzero_profit_margin.reducer;
export const {excludeProductswithzeroprice_profitMargin} = ExcludeProductswithzero_profit_margin.actions;