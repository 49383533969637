/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, Navigate, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {createUserWithPermissions, useAuth} from '../core/Auth'
import {useMsal} from '@azure/msal-react'
import axios from 'axios'
import {useDispatch, useSelector} from 'react-redux'
import {UpdateAzureIdAction} from '../../../redux/azureId'
const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .matches(
      new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ),
      'Wrong email format'
    )
    .min(3, 'Minimum 3 symbols')
    .max(254, 'Email address cannot exceed 254 characters.')
    .required('Email is required'),
  // password: Yup.string()
  //     .min(3, 'Minimum 3 symbols')
  //     .max(50, 'Maximum 50 symbols')
  //     .required('Password is required'),
})

const API_URL = process.env.REACT_APP_KT_API_URL
const initialValues = {
  // email: 'profitmargin@dikoniatest5gmail.onmicrosoft.com',
  email: '',
  // password: 'Dikonia@1234',
  // password: 'dikonia@12',
}
let initialapiData = false
// const initialValues = {
//     email: '',
//     password: '',
// }

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

// var setfalse:any;

// var clickEvent = new MouseEvent('click', {
//     bubbles: true,
//     cancelable: true,
//     view: window
// });

// window.dispatchEvent(clickEvent);
// window.addEventListener('click', function(event) {
//     setfalse=false
// });

let azureCredentials: any

export function Login() {
  const dispatch = useDispatch()
  const data = useSelector((state: any) => state.azureId)
  // console.log('urgygyrey:::::::::', data)
  const {instance, accounts, inProgress}: {instance: any; accounts: any; inProgress: any} =
    useMsal()
  // console.log("qweee::",instance, accounts, inProgress)
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  // const [checkstatus, setCheckstatus]=useState(false)
  const [getToken, setgetToken] = useState('')

  // setCheckstatus(setfalse)

  // const handleLogin = async () => {
  //     try {
  //       const data = await instance.loginPopup();
  //             console.log("teyery",data)
  //         // console.log("Login popup successfully opened.");
  //     } catch (error) {
  //         // console.error("Error opening login popup:", error);
  //         // throw error;
  //     }
  //   }
  // "00000000-0000-0000-32e4-d717102c9f2b.9188040d-6c67-4c5b-b112-36a304b66dad"
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      // console.log("values::::::::<<<<<<>>>>>>>>>>>>",values)
      // setLoading(true)
      // setCheckstatus(false)
      // const j = localStorage.getItem("azureToken");
      try {
        const initialapi = await axios.post(`${API_URL}/auth/validateBeforeLogin`, {
          email: values.email,
        })
        if (initialapi && initialapi.data && initialapi.data.data) {
          azureCredentials = {
            tenantId: initialapi.data.data.tenantId,
            clientId: initialapi.data.data.clientId,
          }
          dispatch(UpdateAzureIdAction(azureCredentials))
        }

        if (initialapi?.data?.data?.isValid == true) {
          initialapiData = true
          if(initialapiData && data.tenantId && azureIdOpen){
            const loginRequest = {
              scopes: ['openid', 'profile', 'user.read'],
              loginHint: values.email, // Replace with the email you want to pre-fill
            };
            let azureToken = await instance.loginPopup(loginRequest)
            const azureLogoutcredential = JSON.stringify(azureToken)
            localStorage.setItem('azureLogoutcredential', azureLogoutcredential)
            const {data: auth} = await login(values.email, 'dikonia@12')
            saveAuth(auth)
            const {data: user} = await getUserByToken()
            setCurrentUser(createUserWithPermissions(user))
            const azureCredentialsString = JSON.stringify(azureCredentials)
            localStorage.setItem('microsoftAzure', azureCredentialsString)
          }
        }
      } catch (error: any) {
        console.error(error)
        saveAuth(undefined)
        setStatus(error.response.data.message)
        setSubmitting(false)
        // setLoading(false)
      }
    },
  })
  const {azureIdOpen}=useSelector((state:any)=>state)
  useEffect(() => {
    if (initialapiData && data.tenantId && azureIdOpen) {
      formik.submitForm();
    }
  }, [initialapiData,data.tenantId,azureIdOpen])
  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
        {/*<div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div>*/}
      </div>
      {/* begin::Heading */}

      {/* begin::Login options */}
      {/*<div className='row g-3 mb-9'>*/}
      {/*  /!* begin::Col *!/*/}
      {/*  <div className='col-md-6'>*/}
      {/*    /!* begin::Google link *!/*/}
      {/*    <a*/}
      {/*      href='#'*/}
      {/*      className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'*/}
      {/*    >*/}
      {/*      <img*/}
      {/*        alt='Logo'*/}
      {/*        src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}*/}
      {/*        className='h-15px me-3'*/}
      {/*      />*/}
      {/*      Sign in with Google*/}
      {/*    </a>*/}
      {/*    /!* end::Google link *!/*/}
      {/*  </div>*/}
      {/*  /!* end::Col *!/*/}

      {/*  /!* begin::Col *!/*/}
      {/*  <div className='col-md-6'>*/}
      {/*    /!* begin::Google link *!/*/}
      {/*    <a*/}
      {/*      href='#'*/}
      {/*      className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'*/}
      {/*    >*/}
      {/*      <img*/}
      {/*        alt='Logo'*/}
      {/*        src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}*/}
      {/*        className='theme-light-show h-15px me-3'*/}
      {/*      />*/}
      {/*      <img*/}
      {/*        alt='Logo'*/}
      {/*        src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}*/}
      {/*        className='theme-dark-show h-15px me-3'*/}
      {/*      />*/}
      {/*      Sign in with Apple*/}
      {/*    </a>*/}
      {/*    /!* end::Google link *!/*/}
      {/*  </div>*/}
      {/*  /!* end::Col *!/*/}
      {/*</div>*/}
      {/* end::Login options */}

      {/* begin::Separator */}
      {/*<div className='separator separator-content my-14'>*/}
      {/*  <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>*/}
      {/*</div>*/}
      {/* end::Separator */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          {/*<div className='text-info'>*/}
          {/*  Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to*/}
          {/*  continue.*/}
          {/*</div>*/}
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          // placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert' style={{color:"red"}}>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}

      {/* <div className='fv-row mb-3'>
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            <input
         type='password'
         autoComplete='off'
         {...formik.getFieldProps('password')}
         className={clsx(
             'form-control bg-transparent',
             {
                 'is-invalid': formik.touched.password && formik.errors.password,
             },
             {
                 'is-valid': formik.touched.password && !formik.errors.password,
             }
         )}
     />
     {formik.touched.password && formik.errors.password && (
         <div className='fv-plugins-message-container'>
             <div className='fv-help-block'>
                 <span role='alert'>{formik.errors.password}</span>
             </div>
         </div>
     )}
 </div>  */}

      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}

        {/* <Link to='/auth/forgot-password' className='link-primary'>
                    Forgot Password ?
                </Link> */}

        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          // disabled={formik.isSubmitting || !formik.isValid }
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      {/*Disable Registration*/}
      {/*<div className='text-gray-500 text-center fw-semibold fs-6'>*/}
      {/*  Not a Member yet?{' '}*/}
      {/*  <Link to='/auth/registration' className='link-primary'>*/}
      {/*    Sign up*/}
      {/*  </Link>*/}
      {/*</div>*/}
    </form>
  )
}