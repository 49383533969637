// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const pricingChannelDetailEditSlice = createSlice({
  name:"pricingChannelDetailEdit",
  initialState:"",
  reducers:{
    UpdatePricingchannelEditAction(state,action){
      return (action.payload)
    },
    ResetUpdatePricingchannelEditAction(){
      return ""
    }
  }
}) 
export default pricingChannelDetailEditSlice.reducer;
export const {UpdatePricingchannelEditAction,ResetUpdatePricingchannelEditAction}= pricingChannelDetailEditSlice.actions ;