// reducers/counterReducer.js
import {createSlice} from '@reduxjs/toolkit'
const selected_channels = createSlice({
  name: 'sellerproductshowinventory',
  initialState: {
    payloadData: [],
  },
  reducers: {
    selected_channels_data(state, action) {
      return action.payload
    },
  },
})
export default selected_channels.reducer
export const {selected_channels_data} = selected_channels.actions
